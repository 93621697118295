import { RouteLocalization } from '~/app/localization';

const routes: RouteLocalization = {
  routes: {
    homepage: {
      name: 'Domácí stránka',
      path: '',
    },
    'search/:fulltext': {
      name: 'Vyhledávání',
      path: 'vyhledavani/:fulltext',
    },
    about: {
      name: 'O nás - Elixír Tours',
      path: 'o-nas',
    },
    contacts: {
      name: 'Kontakty - Elixír Tours',
      path: 'kontakty',
    },
    'data-protection': {
      name: 'Zásady ochrany osobních dat - Elixír Tours',
      path: 'zasady-ochrany-osobnich-dat',
    },
    destinations: {
      path: 'destinace',
      name: 'Všechny destinace',
    },
    ':destination': {
      path: ':destination',
      name: 'Destinace',
    },
    'gift-voucher': {
      name: 'Dárkový poukaz',
      path: 'darkovy-poukaz',
    },
    references: {
      name: 'Reference - Elixír Tours',
      path: 'reference',
    },
    faq: {
      name: 'FAQ - Elixír Tours',
      path: 'faq',
    },
    terms: {
      name: 'Všeobecné obchodní podmínky - Elixír Tours',
      path: 'vseobecne-obchodni-podminky',
    },
    weddings: {
      name: 'Svatby - Elixír Tours',
      path: 'svatby',
    },
    'special-offer': {
      name: 'Akční Nabídka - Elixír Tours',
      path: 'akce',
    },
  },
};

export default routes;
