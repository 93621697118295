import * as VanillaCookieConsent from 'vanilla-cookieconsent';
import { PluginObject } from 'vue';

declare module 'vue/types/vue' {
  export interface Vue {
    $cookieConsent: typeof VanillaCookieConsent;
  }
}

const CookieConsent: PluginObject<{ [key: string]: unknown }> = {
  install: (app) => {
    app.prototype.$cookieConsent = VanillaCookieConsent;
  },
};

export default CookieConsent;
