import { LocaleMessages } from 'vue-i18n';

const i18n: LocaleMessages = {
  app: {
    cookies: {
      settingsButton: 'Nastavení cookies',
      consentModal: {
        title: 'Cookies',
        description:
          'Tento web používá soubory cookies 🍪. Používáním tohoto webu souhlasíte s ukládáním a používáním nezbytných souborů cookies. Zakliknutím tlačítka "Povolit vše" udělujete souhlas k ukládání a používání i dalších souborů cookies jako jsou statistické, preferenční a marketingové.',
        acceptAllBtn: 'Přijmout vše',
        acceptNecessaryBtn: 'Odmítnout vše',
        showPreferencesBtn: 'Nastavení',
      },
      preferencesModal: {
        title: 'Cookies',
        acceptAllBtn: 'Povolit vše',
        acceptNecessaryBtn: 'Zakázat vše',
        savePreferencesBtn: 'Uložit',
        closeIconLabel: 'Zavřít',
        sections: {
          title: 'Vyberte si, jaká nastavení chcete povolit.',
          description:
            '<a href="{url}" target="_blank" rel="noopener noreferrer">Více informací o cookies</a>',
          necessary: {
            title: 'Technické cookies',
            description:
              'Technické cookies jsou nezbytné pro správné fungování webu a všech funkcí, které nabízí. Nepožadujeme Váš souhlas s využitím technických cookies na našem webu. Z tohoto důvodu technické cookies nemohou být individuálně deaktivovány nebo aktivovány.',
          },
          analytics: {
            title: 'Analytické cookies',
            description:
              'Analytické cookies nám umožňují měření výkonu našeho webu a našich reklamních kampaní. Jejich pomocí určujeme počet návštěv a zdroje návštěv našich internetových stránek. Data získaná pomocí těchto cookies zpracováváme souhrnně, bez použití identifikátorů, které ukazují na konkrétní uživatelé našeho webu. Pokud vypnete používání analytických cookies ve vztahu k Vaší návštěvě, ztrácíme možnost analýzy výkonu a optimalizace našich opatření.',
          },
          personalised: {
            title: 'Personalizované soubory cookie',
            description:
              'Používáme rovněž soubory cookie a další technologie, abychom přizpůsobili naše webové stránky potřebám a zájmům našich návštěvníků.',
          },
          marketing: {
            title: 'Reklamní cookies',
            description:
              'Reklamní cookies používáme my nebo naši partneři, abychom Vám mohli zobrazit vhodné obsahy nebo reklamy jak na našich stránkách, tak na stránkách třetích subjektů. Díky tomu můžeme vytvářet profily založené na Vašich zájmech, tak zvané pseudonymizované profily. Na základě těchto informací není zpravidla možná bezprostřední identifikace Vaší osoby, protože jsou používány pouze pseudonymizované údaje. Pokud nevyjádříte souhlas, nebudete příjemcem obsahů a reklam přizpůsobených Vašim zájmům.',
          },
        },
      },
    },
  },
};

export default i18n;
