declare var app_env: object;

let appEnv: { [key: string]: string } = {
  // Set default values/empty values for each environment variable here
  GOOGLE_API_KEY: '',
  GOOGLE_RECAPTCHA_KEY: '',
  GTM_CONTAINER: '',
  NODE_ENV: 'development',
};

if (process.env.VUE_ENV === 'server') {
  for (const appEnvKey in appEnv) {
    if (
      appEnv.hasOwnProperty(appEnvKey) &&
      process.env.hasOwnProperty(appEnvKey)
    ) {
      appEnv[appEnvKey] = process.env[appEnvKey] as string;
    }
  }
} else if (typeof app_env !== 'undefined') {
  let dataIsConsistent = true;

  for (const appEnvKey in appEnv) {
    if (appEnv.hasOwnProperty(appEnvKey)) {
      if (!app_env.hasOwnProperty(appEnvKey)) {
        // tslint:disable
        // @ts-ignore
        console.warn(
          `Window is missing a preselected env variable ${appEnvKey}`
        );
        // tslint:enable
        dataIsConsistent = false;
      }
    }
  }

  if (!dataIsConsistent) {
    // tslint:disable
    // @ts-ignore
    console.error('Could not load the env values from window. Using defaults.');
    // tslint:enable
  } else {
    appEnv = app_env as any;
  }
}

export default appEnv;
