import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { getLocalizedRoute, routes } from '~/app/core/router/routes';

import style from 'vanilla-cookieconsent/dist/cookieconsent.css';
import Visitor from '~/app/core/store/modules/Visitor';
import { getModule } from 'vuex-module-decorators';
import isEnumGenerator from '~/utils/isEnumGenerator';

export enum CookieConsentCategory {
  NECESSARY = 'necessary',
  ANALYTICS = 'analytics',
  PERSONALISED = 'personalised',
  MARKETING = 'marketing',
}

export const isCookieConsentCategory = isEnumGenerator(CookieConsentCategory);

@Component({ style })
export default class extends VueComponent<{}> {
  protected get visitorModule(): Visitor {
    return getModule(Visitor, this.$store);
  }

  public mounted() {
    this.$cookieConsent.run({
      categories: {
        [CookieConsentCategory.NECESSARY]: {
          enabled: true,
          readOnly: true,
        },
        [CookieConsentCategory.ANALYTICS]: {},
        // [CookieConsentCategory.PERSONALISED]: {},
        // [CookieConsentCategory.MARKETING]: {},
      },
      language: {
        default: 'cs',
        translations: {
          cs: {
            consentModal: {
              title: this.$t('app.cookies.consentModal.title').toString(),
              description: this.$t(
                'app.cookies.consentModal.description'
              ).toString(),
              acceptAllBtn: this.$t(
                'app.cookies.consentModal.acceptAllBtn'
              ).toString(),
              acceptNecessaryBtn: this.$t(
                'app.cookies.consentModal.acceptNecessaryBtn'
              ).toString(),
              showPreferencesBtn: this.$t(
                'app.cookies.consentModal.showPreferencesBtn'
              ).toString(),
            },
            preferencesModal: {
              title: this.$t('app.cookies.preferencesModal.title').toString(),
              acceptAllBtn: this.$t(
                'app.cookies.preferencesModal.acceptAllBtn'
              ).toString(),
              acceptNecessaryBtn: this.$t(
                'app.cookies.preferencesModal.acceptNecessaryBtn'
              ).toString(),
              savePreferencesBtn: this.$t(
                'app.cookies.preferencesModal.savePreferencesBtn'
              ).toString(),
              closeIconLabel: this.$t(
                'app.cookies.preferencesModal.closeIconLabel'
              ).toString(),
              sections: [
                {
                  title: this.$t(
                    'app.cookies.preferencesModal.sections.title'
                  ).toString(),
                  description: this.$t(
                    'app.cookies.preferencesModal.sections.description',
                    {
                      url: this.$router.resolve(
                        getLocalizedRoute(routes.dataProtection, this.$router)
                      ).href,
                    }
                  ).toString(),
                },
                {
                  title: this.$t(
                    'app.cookies.preferencesModal.sections.necessary.title'
                  ).toString(),
                  description: this.$t(
                    'app.cookies.preferencesModal.sections.necessary.description'
                  ).toString(),
                  linkedCategory: CookieConsentCategory.NECESSARY,
                },
                {
                  title: this.$t(
                    'app.cookies.preferencesModal.sections.analytics.title'
                  ).toString(),
                  description: this.$t(
                    'app.cookies.preferencesModal.sections.analytics.description'
                  ).toString(),
                  linkedCategory: CookieConsentCategory.ANALYTICS,
                },
                // {
                //     title: this.$t('app.cookies.preferencesModal.sections.personalised.title').toString(),
                //     description: this.$t('app.cookies.preferencesModal.sections.personalised.description').toString(),
                //     linkedCategory: CookieConsentCategory.PERSONALISED,
                // },
                // {
                //     title: this.$t('app.cookies.preferencesModal.sections.marketing.title').toString(),
                //     description: this.$t('app.cookies.preferencesModal.sections.marketing.description').toString(),
                //     linkedCategory: CookieConsentCategory.MARKETING,
                // },
              ],
            },
          },
        },
      },
      onConsent: ({ cookie }) => {
        this.visitorModule.handleCookieChange(cookie);
      },
      onChange: ({ cookie }) => {
        this.visitorModule.handleCookieChange(cookie);
      },
      revision: 1,
    });
  }

  public render() {
    return null;
  }
}
