import { RouteConfig } from 'vue-router';
import { VueRouter } from 'vue-router/types/router';

import { getLocaleFromRouter, localizeRoute } from '~/app/core/router';
import about from './pages/about';
import contact from './pages/contact';
import dataProtection from './pages/dataProtection';
import faq from './pages/faq';
import giftVoucher from './pages/giftVoucher';
import references from './pages/references';
import terms from './pages/terms';
import weddings from './pages/weddings';
import area from './area';
import destination from './destination';
import destinations from './destinations';
import fulltextSearch from './fulltextSearch';
import homepage from './homepage';
import hotel from './hotel';
import specialOffer from './pages/specialOffer';

function getLocalizedRoute(route: RouteConfig, router: VueRouter) {
  return localizeRoute(route, getLocaleFromRouter(router));
}

const routes = {
  about,
  fulltextSearch,
  area,
  contact,
  dataProtection,
  destination,
  destinations,
  faq,
  giftVoucher,
  homepage,
  specialOffer,
  hotel,
  references,
  terms,
  weddings,
};

export { routes, getLocalizedRoute };

// The order is important because of regex routes
export default [
  fulltextSearch,
  homepage,
  about,
  contact,
  dataProtection,
  faq,
  giftVoucher,
  references,
  terms,
  weddings,
  specialOffer,
  hotel,
  area,
  destinations,
  destination,
];
