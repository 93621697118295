import '~/app/core/publicPath';
import '~/app/core/classComponent';
import '~/app/core/scrollto';
import 'elixir-theme/dist/elixir.css';
import '~/styles/main.scss';

import Vue from 'vue';
import Vuetify from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { sync } from 'vuex-router-sync';
import { Components } from 'elixir-theme';

import App from '~/app/App';
import VuetifyConfig from '~/app/core/vuetify';
import { createRouter, createVueApollo, createStore } from '~/app/core';
import { createApolloClient } from '~/app/core/apollo';
import { modules } from '~/app/core/store/modules';
import { createI18n, defaultLocale } from '~/app/localization';
import AbstractModule from '~/app/core/store/modules/AbstractModule';
import push from '~/utils/dataLayer';
import CookieConsent from '~/utils/CookieConsent';

const mergeMountedStrategy = Vue.config.optionMergeStrategies.mounted;
/**
 * When these are fixed, we can use optionMergeStrategies to push the mounted hook from the mixin to the end
 * @link https://github.com/vuejs/vue/issues/9623
 * @link https://stackoverflow.com/questions/52988704/vue-js-scoping-custom-option-merge-strategies-instead-going-global
 */
Vue.config.optionMergeStrategies.mounted = (
  toVal: any,
  fromVal: any,
  vm: any
) => {
  if (vm && vm.mountedBeforeMixins) {
    if (typeof fromVal === 'function') {
      fromVal = [fromVal];
    }
    if (typeof toVal === 'function') {
      toVal = [toVal];
    }
    return [...fromVal, ...toVal];
  }
  return mergeMountedStrategy(toVal, fromVal, vm);
};

// export a factory function for creating fresh app, router and store
// instances
export function createApp(context: any = null) {
  Object.keys(Components).forEach((name) => {
    // @ts-ignore
    Vue.component(name, Components[name]);
  });

  const cookie = context && context.cookie ? context.cookie : '';
  const apolloClient = createApolloClient({ cookie });

  const router = createRouter();
  const store = createStore();
  const apolloProvider = createVueApollo({ apolloClient });
  const i18n = createI18n();
  const vuetify = new Vuetify({
    lang: {
      t: (key: string, ...params: (string | number)[]) =>
        i18n.t(key, params).toString(),
      current: defaultLocale,
    },
    ...VuetifyConfig,
  });

  sync(store, router);

  router.beforeEach((to, from, next) => {
    let found = false;
    for (const routeRecord of to.matched) {
      if (routeRecord.meta.locale) {
        i18n.locale = routeRecord.meta.locale;
        found = true;
      }
    }

    if (!found) {
      i18n.locale = defaultLocale;
    }

    next();
  });

  router.afterEach((to, from) => {
    if (from.name !== null) {
      push({
        event: 'Pageview',
        pagePath: `${window.location.protocol}//${window.location.hostname}${to.fullPath}`,
      });
    }
  });

  Vue.use(CookieConsent);

  const app = new Vue({
    router,
    apolloProvider,
    store,
    vuetify,
    i18n,
    beforeCreate: () => {
      // Set apollo to the store modules
      for (const moduleName in modules) {
        if (!modules.hasOwnProperty(moduleName)) {
          continue;
        }

        const module = (getModule(
          modules[moduleName] as any,
          store
        ) as unknown) as AbstractModule;
        module.apollo = apolloClient;
      }
    },
    // the root instance simply renders the App component.
    render: (h) => h(App),
  });
  return { app, router, store };
}
