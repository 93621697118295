import { IS_MOBILE_BY_DEFAULT } from '~/../node_modules/elixir-theme/src/constants/MobileConstants';
import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import { HotelModel } from '~/utils/hotel';
import { HotelDescription, HotelGallery } from '~/components/organisms/hotel';
import { getModule } from 'vuex-module-decorators';
import Hotel from '~/app/core/store/modules/Hotel';
import { GQLImage } from '~/GqlTypes';

interface HotelInformationSection {
  hotelData: HotelModel;
  isMobile?: boolean;
}

@Component
export default class extends VueComponent<HotelInformationSection>
  implements HotelInformationSection {
  @Prop({ default: IS_MOBILE_BY_DEFAULT, type: Boolean })
  public isMobile!: boolean;

  @Prop({ required: true })
  public hotelData!: HotelModel;

  protected loadingGallery: boolean = false;

  public get hotelModule(): Hotel {
    return getModule(Hotel, this.$store);
  }

  protected get content(): string {
    return this.hotelData.excerpt + this.hotelData.content;
  }

  protected get galleryItems(): GQLImage[] {
    return this.hotelModule.currentGalleryId &&
      this.hotelModule.hotelGalleryCache.hasOwnProperty(
        this.hotelModule.currentGalleryId
      )
      ? this.hotelModule.hotelGalleryCache[this.hotelModule.currentGalleryId] ||
          []
      : [];
  }

  public mounted() {
    this.hotelModule.loadHotelGallery({ id: this.hotelData.id });
  }

  public render() {
    return (
      <v-container class='px-lg-0 pb-0'>
        <v-row>
          <v-col cols='12' md='6' class={this.isMobile ? 'px-3' : ''}>
            <HotelDescription hotelData={this.hotelData} />
          </v-col>
          <v-col cols='12' md='6'>
            <HotelGallery
              galleryItems={this.galleryItems}
              loading={this.hotelModule.hotelGalleryLoading}
              key={this.hotelData.id}
            />
          </v-col>
        </v-row>
      </v-container>
    );
  }
}
