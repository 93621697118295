import appEnv from '~/app/core/appEnv';

const src = '//www.googletagmanager.com/gtm.js?id=';

export default function(): void {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const lastScript = document.getElementsByTagName('script');
  if (lastScript.length < 1) {
    return;
  }

  const gtmScript = document.createElement('script');
  gtmScript.async = true;
  gtmScript.src = `${src}${appEnv.GTM_CONTAINER}`;
  const lastScriptElement = lastScript[lastScript.length - 1];
  if (!lastScriptElement || !lastScriptElement.parentNode) {
    return;
  }
  lastScriptElement.parentNode.insertBefore(gtmScript, lastScriptElement);
}
