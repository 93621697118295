export default {
  app: {
    benefits: {
      button: 'Představení Elixír tours',
      header: 'Proč si vybrat dovolenou s Elixírem',
      luxury: 'Laskavá péče 24/7',
      specialists: 'Naše ceny mile překvapí',
      tested: 'Dovolená, která zůstane ve Vašem srdci',
      verification: 'Ověřeno našimi travel specialisty',
    },
  },
};
