import { RouteConfig } from 'vue-router';

import GiftVoucher from '~/views/GiftVoucher';

const config: RouteConfig = {
  component: GiftVoucher,
  path: 'gift-voucher',
};

export default config;
